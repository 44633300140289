import TypeWriter from './typewriter.js';
import '../css/main.css';

window.onload = function () {
    var elements = document.getElementsByClassName('typewriter');
    for (var i = 0; i < elements.length; i++) {
        var text = elements[i].getAttribute('data-text');
        if (text) {
            new TypeWriter(elements[i], text);
        }
    }

    var footerCopyright = document.getElementById('copyright-date');
    footerCopyright.innerText = "Diple © " + new Date().getFullYear();
};